#hamburgerOuter {

    margin: 0 0 0 auto;

    width: 36px;
    height: 36px;
    position: relative;

    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    & path {
        @apply transition-colors duration-300;
    }

    & span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;

    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 8px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 16px;
      transform-origin: left center;
    }
  }

  &.active {
    & span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0px;
        left: 6px;
        opacity: 0;
        /* & path {
            fill: #f7f7f3;
        } */
      }

      &:nth-child(2) {
        transform: rotate(112deg);
        width: 0%;
        translate: 24px -18px
       
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 26px;
        left: 6px;
        /* & path {
            fill: #f7f7f3;
        } */
      }
    }
  }
}

#dropDownMenu.active {
        opacity: 90%;
        min-width: 350px;
        transform: scale(1);
}

#dropDownMenu {
    position: absolute;
    top: -10px;
    right: -10px;

  z-index: 30;

  background-color: #df3f1c;


  opacity: 0%;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;

  min-width: 350px;
  overflow-y: scroll;

  @media (max-width: 1300px) {
    min-width: 270px;
  }

  clip-path: polygon(
    0% 8px,
    8px 0%,
    calc(100% - 8px) 0%,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    0 calc(100% - 8px)
  );

  
  

  & .menu-items {
    padding: 60px;

    height: 100%;
    max-height: calc(100vh - 120px - 20px - 20px);
    overflow-y: scroll;
  }

  & ol {
    padding: 0;
    margin: 0;
  }

  & li,
  & button {
    font-size: calc(20px + (36 - 20) * ((100vh - 400px) / (1000 - 400)));
    line-height: 120%;
    color: rgba(253, 252, 252, 0.8);

    text-align: center;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  & li.divider {
    & svg {
      margin: 15px auto;
    }
  }

  & .sub-menu {
    margin: 5px 0 0 0;
  }

  & li.sub-menu-item {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;

    @media (max-width: 1300px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
}