.menus.active {
  @apply translate-x-0;
}

.menus .divider {
  @apply py-3;
}

.menus ul {
  @apply  md:pt-10 pb-12 px-5 text-center md:text-left md:px-20 space-y-1 md:space-y-3 font-review text-2_75xl tracking-wide flex flex-col items-center md:block;
}

.menus {
  @apply fixed top-0 right-0 w-full md:w-auto md:max-w-max h-full z-[99999]  opacity-90 shadow-xl transform translate-x-full transition-transform duration-300  py-10 overflow-scroll;
}


.menuButtonsOuter {
  @apply gap-1 flex flex-wrap md:flex-col px-36 md:px-20  justify-center md:justify-start ;
}

.menuButtonsOuter > div {
  @apply self-center md:self-start;
}

.menuButton {
  @apply px-2.5 py-1 font-review uppercase  w-max text-[1rem] tracking-[0.13em] border text-center;
}

#productionMenu .menuButton {
  @apply bg-offBlack text-offWhite  hover:text-offBlack hover:bg-offWhite hover:border-offBlack transition duration-500;
}

#studiosMenu .menuButton {
  @apply
   bg-offWhite text-offBlack hover:text-offWhite hover:bg-offBlack hover:border-offWhite transition duration-500;
}


.turnLinkOuter {
  
  perspective: 1000px;
}

.turnLinkInner {
  @apply relative w-full h-full transition-transform delay-[40ms] ease-in-out duration-[450ms];
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  

}

@keyframes rotateAnimation {
  0% {
    transform: rotateX(0deg);
  }
  /* 48% {
    transform: rotateX(180deg);
  } */
 
  100% {
    transform: rotateX(180deg);
  }
}

.turnLinkInner:hover  {
  /* transform: rotateX(180deg); */
  
  /* animation-delay: 10ms;
  animation-duration: 580ms;
  animation-name: rotateAnimation;
  animation-fill-mode: forwards;
  animation-timing-function: ease; */

  transform: rotateX(180deg);
  transform-style: preserve-3d;
}

