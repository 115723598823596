@font-face {
  font-family: "Dia";
  src: url("../fonts/ABCDiatype-Regular.woff2") format("woff2"),
    url("../fonts/ABCDiatype-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;

  
}

@font-face {
  font-family: "Dia";
  src: url("../fonts/ABCDiatype-RegularItalic.woff2") format("woff2"),
    url("../fonts/ABCDiatype-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-feature-settings: "calt" 1, "liga" 1, "rlig" 1, "rvrn" 1, "kern" 1, "rclt" 1, "ss07" 1;
}


@font-face {
  font-family: "Review";
  src: url("../fonts/Review-Medium-Web.woff2") format("woff2"),
    url("../fonts/Review-Medium-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-feature-settings: "ss03" 1, "ss05" 1;
}


  
 