
body {
  @apply antialiased;
}

h1 {
  @apply text-3xl md:text-[2.25rem] font-review font-medium;
}

strong {
  @apply font-medium;
}

a  img, a .imgZoom {
  @apply transform transition-transform !duration-500 hover:scale-[103%] hover:cursor-crosshair;
}

a .noZoom {
  @apply hover:scale-100 transform-none hover:cursor-pointer;
}

.bigText {
  @apply prose md:w-[56ch] text-2_5xl md:text-[28px] leading-[1.35] font-sans text-center m-auto ;

  & a {
    text-decoration: underline !important;
    text-decoration-color: #DF3F1C;
    text-decoration-thickness: .05em;
    text-underline-offset: 4px;

  }

  & a:hover {
    @apply text-pvRed transition-all duration-300;
  }
  
}

.personName {
  @apply text-xl md:text-[28px] font-review text-[91%];
}

.vidCaption {
  @apply absolute bottom-0 left-0 w-full pb-6 pl-6;
}

.vidCaption-inner {
  @apply m-auto font-sans text-shadow-md text-offWhite z-10;

  & h2 {
    @apply text-xl md:text-[28px];
  }

  & p {
    @apply text-base md:text-lg md:pt-2;
  }
}

.talentList {
  @apply text-2xl md:text-[2.6vw] font-review text-white opacity-90 text-center space-y-4 md:space-y-10;
}


/* ----------- end type styles ----------- */




.galleryHeight {
  height: calc(100% - 65px)
}


.logos path {
  @apply transition-colors duration-200;
}

.generalButton {
  @apply  py-2 px-4 w-max m-auto uppercase text-sm font-review border  transition-all tracking-[0.12em] duration-300 ;
}

.buttonProduction {
  @apply border-offBlack bg-offWhite text-offBlack hover:bg-offBlack hover:text-offWhite hover:border-offWhite;
}

.buttonStudios {
  @apply border-offBlack bg-offBlack text-offWhite hover:bg-offWhite hover:text-offBlack;
}

.filmCatButton {
  @apply px-2.5 py-1 text-offWhite  uppercase w-max text-[1rem] tracking-[0.13em] border-offWhite border font-review hover:bg-offWhite hover:text-offBlack hover:opacity-80   transition-all duration-200;
}

.filmCatButton.active {
  @apply bg-offWhite text-offBlack  select-none;
}

/* ----------- Home ----------- */

.homeHalf {
  @apply w-1/2 h-screen overflow-scroll;
}

.homeTop {
  @apply h-screen w-full relative;
}

.homeTop .singleImage {
  @apply w-full h-full object-cover;
}

.homeButton {
  @apply bg-pvRed px-4 pt-3 pb-3 text-offWhite text-sm uppercase m-auto w-max font-medium;
}

.homeTopOverlay {
  @apply  absolute top-0 left-0 w-full h-full flex items-center z-20 text-center justify-center text-5xl md:text-6xl font-review text-offWhite;
}

.homeTopDark {
  @apply absolute top-0 left-0 w-full h-full z-10 bg-black opacity-10;
}


.webkit-fill {
  height: 100vh;
  height: 100cqh;
}

/* .photGridDiv {
  width: calc(50% - 5rem)
} */

.galleryAspect {
  @apply aspect-[5/3];
}





.flipper {
  @apply w-full text-center h-[30vh];
  perspective: 1000px;
}

.flipperInner {
  @apply relative w-full h-full text-center transition-transform duration-700;
  transform-style: preserve-3d;
}

.flipper.active .flipperInner {
  transform: rotateX(180deg)
}

.flipperFront, .flipperBack {
  @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flipperItem {
  @apply hidden;
}

.flipText {
  @apply text-3xl md:text-[2.5rem] transition-opacity duration-200 ;
}

.flipperBack {
  transform: rotateX(180deg);
}





/* purgecss start ignore */

video {
  @apply max-h-full;
}

.vidCover video {
  @apply w-full h-full object-cover opacity-0 transition-opacity duration-1000;
}

.vidCover[data-can-play] video {
  @apply opacity-100;
}

.fullLengthPlayer media-poster, .vidCover media-poster {
  @apply w-full h-full absolute top-0 left-0 -z-10;
}

.fullLengthPlayer media-poster {
  @apply flex items-center justify-center;
}

.fullLengthPlayer media-poster {
  @apply w-full h-full object-contain;
}

.fullLengthPlayer media-provider {
  @apply bg-black;
}

.fullLengthPlayer media-provider {
  @apply opacity-0 transition-opacity duration-200;
}

.fullLengthPlayer[data-can-play] media-provider {
  @apply opacity-100;
}

.vidCover media-poster img {
  @apply w-full h-full object-cover;
}


.swiper-pagination-bullet-active {
  @apply bg-offBlack !important;
}


.twic-blur {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out !important;
  }

  .twic-done {
    opacity: 1;
    transition: opacity 1s linear, transform .75s ease-out !important;
  }

  .twic-done ~ .swipePic {
    @apply -translate-y-full; 
  }

/* purgecss end ignore */